import React from "react"
//import "../../styles/mystyles.scss"
import { Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

class CastIndex extends React.Component {
  constructor(props) {
    super(props)
    this.selectChar = this.selectChar.bind(this)
  }

  selectChar(e) {
    e.preventDefault()
    navigate("/plays/" + this.props.playId + "/cast/" + e.target.value)
  }

  render() {
    let playId = this.props.playId
    var castPage = this.props.castPage
    let charMapImage = this.props.charMapImage
    const charData = this.props.castPage.charData
    let thumbnailMappings = {}
    for (var edge of this.props.thumbnails.edges) {
      let node = edge.node
      let i = node.relativePath.lastIndexOf("/")
      let charId = node.relativePath.substring(
        i + 1,
        node.relativePath.length - 4
      )
      thumbnailMappings[charId] = node.childImageSharp.gatsbyImageData
    }

    return (
      // On desktop/table, show the picture list
      <>
        <div className="is-hidden-mobile">
          <div className="card block is-hidden">
            <header className="card-header">
              <div className="card-header-title">
                <h5 className="title is-5 has-text-info">Relationship Map</h5>
              </div>
            </header>
            <div className="card-image">
              <GatsbyImage
                image={charMapImage.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
                alt=""
              />
            </div>
          </div>
          {castPage.index.groupings.map((grouping) => {
            let chars = grouping.chars.map((char) => {
              return (
                <div
                  className="column is-half pl-0"
                  key={"index_" + char.charId}
                >
                  <div
                    className={
                      char.charId === charData.charId
                        ? "box cast-listing-box has-background-sibredorange py-0 ml-0"
                        : "box cast-listing-box has-background-sibgrey2lighter py-0"
                    }
                  >
                    <Link to={"/plays/" + playId + "/cast/" + char.charId}>
                      <div className="columns is-vcentered is-mobile">
                        <div className="column py-0 pl-0">
                          <div>
                            <GatsbyImage
                              image={thumbnailMappings[char.charId]}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="column py-0">
                          <div
                            className={`title has-text-left cast-listing-label ${
                              char.charId === charData.charId
                                ? "has-text-white"
                                : "has-text-sibgrey6darker"
                            } ${
                              char.charDescShort ===
                                "Rosencrantz and Guildenstern" ||
                              char.charDescShort === "Gravediggers" ||
                              char.charDescShort === "Other Conspirators" ||
                              char.charDescShort === "Artemidorus"
                                ? "title cast-listing-label-long"
                                : ""
                            }
                            
                            `}
                          >
                            {char.charDescShort}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              )
            })
            return (
              <div className="container py-1" key={grouping.groupDisplayName}>
                <h5 className="title is-5 has-text-sibblack">
                  {grouping.groupDisplayName}
                </h5>
                <div className="columns pl-1 is-multiline centered is-mobile">
                  {chars}
                </div>
              </div>
            )
          })}
        </div>
        {/* On mobile, just show a basic dropdown */}
        <div className="container is-hidden-tablet">
          <div className="columns is-mobile is-vcentered px-1 mt-1">
            <div className="column">
              <h6 className="title is-6 has-text-sibblack">Select Character</h6>
            </div>
            <div className="column">
              <div className="select is-sibblack is-rounded is-pulled-right">
                <select
                  onChange={(e) => this.selectChar(e)}
                  onBlur={(e) => this.selectChar(e)}
                  defaultValue={charData.charId}
                >
                  {castPage.index.groupings.map((grouping) => {
                    let result = grouping.chars.map((char) => {
                      return (
                        <option value={char.charId} key={char.charId}>
                          {char.charDescShort}
                        </option>
                      )
                    })
                    return result
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default CastIndex
