import React from "react"
//import "../../styles/mystyles.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import parse from "html-react-parser"

class CastDetail extends React.Component {
  render() {
    const charData = this.props.castPage.charData
    var detailTextId = 0
    return (
      <div className="container py-1">
        <nav className="columns px-1">
          <div className="column">
            <h4 className="title is-4 has-text-sibblack">
              {charData.charDescLong}
            </h4>
            <div
              className={charData.attribute1Label == null ? "is-hidden" : ""}
            >
              <h5 className="title has-text-sibblack is-5 mb-1">
                {charData.attribute1Label}:{" "}
              </h5>
              {charData.attribute1Values}
            </div>
            <div
              className={charData.attribute2Label == null ? "is-hidden" : ""}
            >
              <h5 className="title has-text-sibblack is-6 mb-1 mt-2">
                {charData.attribute2Label}:{" "}
              </h5>
              {charData.attribute2Values}
            </div>
            <div
              className={`mt-3 ${
                charData.associates.length === 0 ? "is-hidden" : ""
              }`}
            >
              <h5 className="title has-text-sibblack is-5">Associates: </h5>
              {charData.associates.map((ass) => {
                return (
                  <Link
                    to={"/plays/" + this.props.playId + "/cast/" + ass.charId}
                    key={ass.charId}
                  >
                    <h6 className="title is-6 has-text-sibredorange pb-1">
                      {ass.dispName}
                    </h6>
                  </Link>
                )
              })}
            </div>
          </div>
          <div className="column">
            <div className="has-text-centered-mobile">
              <GatsbyImage
                image={this.props.charImage.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
                alt=""
              />
            </div>
          </div>
        </nav>
        <div className="content px-1">
          {charData.descriptionText.map((line) => {
            return (
              <div
                className="content"
                key={`cast-detail-content-${detailTextId++}`}
              >
                {parse(line)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default CastDetail
