import React from "react"
import { connect } from "react-redux"

import Layout from "../components/common/layout/layout"
import CastPage from "../components/product/sibplay/cast/castpage"

import { setLastCharForPlay } from "../state/app"
import PrivateContent from "../components/user/privatecontent"

import { graphql } from "gatsby"

const CastPageContainer = (props) => {
    const { pageContext } = props
    const { playId, castPage, playTitle, manifest } = pageContext
    props.dispatch(setLastCharForPlay(playId, castPage.charData.charId))
    return (
      <Layout>
        <PrivateContent accessLevel={castPage.charData.access}>
          <CastPage
            playId={playId}
            playTitle={playTitle}
            manifest={manifest}
            castPage={castPage}
            pageContext={pageContext}
            thumbnails={props.data.thumbnails}
            charImage={props.data.charImage}
            charMapImage={props.data.charMapImage}
          />
        </PrivateContent>
      </Layout>
    )
}

export const query = graphql`query CastThumbnailImages($castThumbnailRegex: String, $charImageRegex: String, $relationMapRegex: String) {
  thumbnails: allFile(filter: {relativePath: {regex: $castThumbnailRegex}}) {
    edges {
      node {
        relativePath
        childImageSharp {
          gatsbyImageData(width: 100, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
  }
  charImage: file(relativePath: {eq: $charImageRegex}) {
    childImageSharp {
      gatsbyImageData(width: 720, placeholder: BLURRED,  layout: CONSTRAINED)
    }
  }
  charMapImage: file(relativePath: {eq: $relationMapRegex}) {
    childImageSharp {
      gatsbyImageData(width: 1024, placeholder: BLURRED, layout: CONSTRAINED)
    }
  }
}
`

export default connect(
  (state) => ({
    lastChars: state.app.lastChars,
  }),
  null
)(CastPageContainer)
