import React, { useState } from "react"
import CastIndex from "./castindex"
import CastDetail from "./castdetail"
import PlayNav from "../playnav/playnav"
import Seo from "../../../common/layout/seo"
import { GatsbyImage } from "gatsby-plugin-image"

import PlaySVG from "../../../../scss/img/icon/sibsvg/sib-icon-theatre.svg"

const CastPage = (props) => {
  const [showRelations, setShowRelations] = useState(false)

  const toggleRelations = () => {
    setShowRelations(!showRelations)
  }

  return (
    <>
      <Seo title={props.playTitle} />
      <PlayNav
        playId={props.playId}
        playTitle={props.playTitle}
        section="cast"
        manifest={props.manifest}
      />
      <div className="outer-keypage-container outer-castpage-container container py-0 mt-0 mb-4">
        <div className={`columns my-0 ${showRelations ? "is-hidden" : ""}`}>
          <div className="column outer-cast-index">
            <CastIndex
              playId={props.playId}
              castPage={props.castPage}
              thumbnails={props.thumbnails}
              charMapImage={props.charMapImage}
            />
            <div className="relationship-map-select-container has-text-centered-mobile">
              <div className="title is-5 is-hidden-mobile">
                Learn how these characters are related:
              </div>
              <div
                className="button is-outlined is-sibgrey6darker"
                tabIndex={0}
                role="button"
                onClick={(e) => toggleRelations()}
                onKeyDown={(e) => toggleRelations()}
              >
                <PlaySVG />
                &nbsp;View Relationship Map
              </div>
            </div>
          </div>
          <div className="column">
            <CastDetail
              playId={props.playId}
              castPage={props.castPage}
              charImage={props.charImage}
            />
          </div>
        </div>
        <div className={`columns my-0 ${showRelations ? "" : "is-hidden"}`}>
          <div className="column">
            <div className="title is-6 pt-1 has-text-centered">
              Relationship Map
            </div>
            <div className="has-text-centered">
              <GatsbyImage
                image={props.charMapImage.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
                alt=""
              />
            </div>
            <div className="has-text-centered py-2">
              <div
                className="button is-outlined is-sibgrey6darker"
                tabIndex={0}
                role="button"
                onClick={(e) => toggleRelations()}
                onKeyDown={(e) => toggleRelations()}
              >
                <PlaySVG />
                &nbsp;Back to Cast
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CastPage
